<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import FeedTile from '/~/extensions/health/components/feed/tile/feed-tile'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useSwiper } from '/~/composables/base/use-swiper'

const props = withDefaults(
  defineProps<{
    title?: string
    type?: 'challenge' | 'recipe' | 'article'
    slidesPerView?: number
    spaceBetween?: number
    slides?: {
      id: string
      raw: {
        objectID: string
      }
    }[]
    loop?: boolean
    loading?: boolean
  }>(),
  {
    type: 'challenge',
    slidesPerView: 4,
    spaceBetween: 14,
    slides: () => [],
    loop: false,
    loading: false,
  }
)

const { swiperContainer, currentIndex, initSwiper, destroySwiper } = useSwiper(
  computed(() => ({
    spaceBetween: 30,
    slidesOffsetBefore: 20,
    slidesPerView: props.slidesPerView,
    loop: props.loop,
    observer: true,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  }))
)

watch(
  () => props.loading,
  (value) => {
    if (!value) {
      initSwiper()
    }
  }
)

onMounted(() => {
  if (!props.loading) {
    initSwiper()
  }
})

onBeforeUnmount(destroySwiper)
</script>

<template>
  <div>
    <h2 v-if="title" class="mb-2.5 ml-5 font-bold">
      {{ title }}
    </h2>
    <div ref="swiperContainer" class="swiper-container w-full">
      <div class="swiper-wrapper flex">
        <base-placeholder
          v-if="loading"
          class="widget-popular__placeholder"
          :columns="1"
          height="110"
        />
        <template v-else>
          <div
            v-for="(slide, index) in slides"
            :key="`slide-${index}-${slide.raw && slide.raw.objectID}`"
            class="swiper-slide relative h-auto w-full p-5 xxs:p-0 sm:p-0"
          >
            <!-- @vue-ignore -->
            <feed-tile :tile="slide" :type="type" />
          </div>
        </template>
      </div>
    </div>
    <div
      ref="pagination"
      class="mt-5 flex w-full items-center justify-center space-x-2.5"
    >
      <span
        v-for="(item, index) in slides"
        :key="item.id"
        :class="[index === currentIndex ? 'w-10 bg-primary' : 'bg-strong']"
        class="ease h-2.5 w-2.5 rounded-md transition-width duration-200"
      />
    </div>
  </div>
</template>
